import bind from 'bind-decorator';
import React from 'react';
import FontAwesome from 'react-fontawesome';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Form, FormFeedback, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import './SearchBar.scss';

interface State {
  searchKey: string;
  isValid: boolean;
}

class SearchBar extends React.PureComponent<RouteComponentProps, State>{
  public readonly state: State = {
    searchKey: '',
    isValid: true,
  };

  public render() {
    const { searchKey, isValid } = this.state;
    return (
      <div className='SearchBar'>
        <Form onSubmit={this.onSubmit}>
          <InputGroup>
            <Input invalid={!isValid}
              required type="text"
              value={searchKey}
              onChange={this.onChange}
              placeholder="Search" />
            <FormFeedback tooltip>Invalid search key. Exclude /.</FormFeedback>
            <InputGroupAddon addonType="append">
              <Button disabled={searchKey === '' || !isValid} className="search-button">
                <FontAwesome name='search' />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Form>
      </div>
    );
  }

  @bind
  private onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const searchValue = e.currentTarget.value;
    this.setState({
      searchKey: searchValue,
      isValid: !searchValue.includes('/')
    });
  };

  @bind
  private onSubmit(e: React.FormEvent) {
    const { searchKey } = this.state;
    const { history } = this.props;

    e.preventDefault();
    history.push(`?search=${encodeURIComponent(searchKey)}`)
  }
}

export default withRouter(SearchBar);
