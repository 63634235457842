import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import './Home.scss';
import PostsLists from './PostsList';
import { UserConsumer } from './UserContext';

export default function Home() {
  return (<UserConsumer>{user =>
    <div className='Home'>
      <h1>Welcome to IPFX support portal</h1>
      <PostsLists />
      <hr />
      <h2>IPFX resources</h2>
      <div className='media'>
        <div className='media-box'>
          <FontAwesome name='compact-disc' />
          <p>Software Downloads</p>
          <Button tag={Link} to='/downloads/software/' color='primary'>More Info</Button>
        </div>
        <div className='media-box'>
          <FontAwesome name='book' />
          <p>Manual Downloads</p>
          <Button tag={Link} to='/downloads/manuals/' color='primary'>More Info</Button>
        </div>
      </div>
      <hr />
      <h2>Software Assurance</h2>
      <div className='media'>
        <div className='media-box'>
          <FontAwesome name='user' />
          <p>Support</p>
          <Button tag={Link} to='/support' color='primary'>More Info</Button>
        </div>
      </div>
      <hr />
    </div>
  }</UserConsumer>);
}
