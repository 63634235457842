import bind from 'bind-decorator';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import { saveNewPassword } from '../api';
import './PasswordChange.scss';

interface State {
  password: string;
  confirmPassword: string;
  error: boolean;
}

class PasswordChange extends React.PureComponent<RouteComponentProps, State> {
  public readonly state: State = {
    password: '',
    confirmPassword: '',
    error: false,
  };

  public render() {
    const { password, confirmPassword, error } = this.state;

    return (
      <div className="PasswordChange">
        <header>Change Password</header>
        <Form className="form" onSubmit={this.onSubmit} disabled={error}>
          <FormGroup>
            <Input required type="password" name='password' autoComplete='new-password' placeholder="Enter Password" value={password} onChange={this.onChange} />
          </FormGroup>
          <FormGroup>
            <Input required type="password" name='confirmPassword' autoComplete='new-password' placeholder="Confirm Password" value={confirmPassword} onChange={this.onChange} />
          </FormGroup>
          <FormGroup>
            <Button block type='submit' color="primary">Save Password</Button>
          </FormGroup>
          {error && <div className='error'>Passwords should match</div>}
        </Form>
      </div>
    )
  }

  @bind
  private onChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ [e.currentTarget.name]: e.currentTarget.value, error: false } as any);
  };

  @bind
  private async onSubmit(e: React.FormEvent<any>) {
    e.preventDefault();

    const { password, confirmPassword } = this.state;
    if (password !== confirmPassword) {
      this.setState({ error: true });
      return;
    }

    await saveNewPassword(password);
    this.props.history.replace('/');
  }
}

export default withRouter(PasswordChange);
