import bind from 'bind-decorator';
import React from 'react';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import './OrderRemoval.scss';
import Toolbar from './Toolbar';


interface Props {
  onRemove: (reason: string) => void;
  onCancel: () => void;
}

interface State {
  reason: string;
}

export default class OrderRemoval extends React.PureComponent<Props, State> {
  public state: State = { reason: '' };

  public render() {
    return <Form className='OrderRemoval' onSubmit={this.onSubmit}>
      <FormGroup>
        <Input type='textarea' placeholder='Reason for removal (optional)' value={this.state.reason} onChange={this.onChangeReason} />
      </FormGroup>
      <FormGroup>
        <Toolbar>
          <Button className='pull-right' onClick={this.props.onCancel}>Cancel</Button>
          <Button className='pull-right' type='submit' color='danger'>Remove</Button>
        </Toolbar>
      </FormGroup>
    </Form>;
  }

  @bind
  private onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    this.props.onRemove(this.state.reason);
  }

  @bind
  private onChangeReason(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      reason: e.currentTarget.value
    });
  }
}
