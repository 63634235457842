import bind from 'bind-decorator';
import React from 'react';
import FontAwesome from 'react-fontawesome';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import { addPost, Post } from '../api';
import './PostForm.scss';

interface NavParams {
  id: string | 'new';
}

interface State {
  title: string;
  content: string;
  img?: string;
}

type Props = RouteComponentProps<NavParams> & {
  post: Post;
}

export default class PostForm extends React.PureComponent<Props, State> {
  public readonly state: State = { title: '', content: '' };
  private readonly fileRef = React.createRef<HTMLInputElement>();

  public render() {
    const { title, content, img } = this.state;
    return (
      <div className="PostForm">
        <header>Add New Post</header>
        <Form onSubmit={this.onSubmit}>
          <FormGroup>
            <Input required type="text" name="title" id="title" value={title} placeholder="Title" onChange={this.onChange} />
          </FormGroup>
          <FormGroup>
            <Input required type="textarea" className="description" name="content" id="content" value={content} placeholder="Description" onChange={this.onChange} />
          </FormGroup>
          <FormGroup className="file-upload">
            {img ? <img alt="" src={img} className="preview" /> : <FontAwesome className="icon" name="image" />}
            <Button size="sm" tag="label">
              Browse <Input type="file" hidden innerRef={this.fileRef} onChange={this.fileChange} accept="image/*" />
            </Button>
          </FormGroup>
          <FormGroup>
            <Button color="primary" size="sm" type="submit">Add Post</Button>
          </FormGroup>
        </Form>
      </div >
    );
  }

  public componentWillUnmount() {
    const { img } = this.state;

    if (img) {
      URL.revokeObjectURL(img);
    }
  }

  @bind
  private async onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const { title, content, } = this.state;

    const post = {
      id: 0,
      title,
      content,
    } as Post;

    const { current: file } = this.fileRef;

    if (file && file.files && file.files.length) {
      post.thumbnail = file.files[0];
    }
    await addPost(post);
    this.goBack();
  }

  private goBack() {
    const { history } = this.props;
    history.replace(`/`);
  }

  @bind
  private onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.currentTarget;
    this.setState({ [name]: value } as any);
  };

  @bind
  private fileChange(e: React.ChangeEvent<HTMLInputElement>) {
    let { img } = this.state;

    if (img) {
      URL.revokeObjectURL(img);
    }

    if (e.target.files && e.target.files[0]) {
      img = URL.createObjectURL(e.target.files[0]);
    } else {
      img = undefined;
    }

    this.setState({ img });
  }
}
