import bind from 'bind-decorator';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { logout, UserInfo, whoAmI } from './api';
import './App.scss';
import ELearning from './components/ELearning';
import Enquiry from './components/Enquiry';
import Header from './components/Header';
import Home from './components/Home';
import Layout from './components/Layout';
import Login from './components/Login';
import Manuals from './components/Manuals';
import Order from './components/Order';
import Orders from './components/Orders';
import PasswordChange from './components/PasswordChange';
import PasswordReset from './components/PasswordReset';
import Post from './components/PostArticle';
import AddPost from './components/PostForm';
import Software from './components/Software';
import Support from './components/Support';
import { ToasterProvider } from './components/Toaster';
import { UserProvider } from './components/UserContext';
import User from './components/UserForm';
import UserList from './components/UserList';

interface State {
  user?: UserInfo | null;
}

class App extends React.PureComponent<{}, State> {
  public readonly state: State = {};

  public async componentDidMount() {
    this.setState({ user: await whoAmI() });
  }

  public render() {
    return <Switch>
      <Route path='/password/reset' component={PasswordReset} />
      <Route render={this.renderApp} />
    </Switch>
  }

  @bind
  private renderApp() {
    const { user } = this.state;
    if (user === undefined) { return null; }

    if (!user) {
      return <Login onLogin={this.onLogin} />;
    }

    return (
      <div className='App'>
        <UserProvider value={user}>
          <ToasterProvider>
            <Layout>
              <Header onLogout={this.onLogout} />
              <Switch>
                <Route exact path='/' component={Home} />
                <Route strict path='/downloads/software/:path*' component={Software} />
                <Route strict path='/downloads/manuals/:path*' component={Manuals} />
                <Route strict path='/downloads/e-learning/:path*' component={ELearning} />
                <Route exact path='/support' component={Support} />
                <Route path='/support/enquiry' component={Enquiry} />
                <Route exact path='/users' component={UserList} />
                <Route path='/users/edit/:id' component={User} />
                <Route path='/password/change' component={PasswordChange} />
                <Route path='/post/add' component={AddPost} />
                <Route path='/post/:id' component={Post} />
                <Route path='/login' component={Login} />
                <Route exact path='/orders/:id?' component={Orders} />
                <Route exact path='/orders/edit/:id' component={Order} />
              </Switch>
            </Layout>
          </ToasterProvider>
        </UserProvider>
      </div>
    );
  }

  @bind
  private onLogin(user: UserInfo) {
    this.setState({ user });
  }

  @bind
  private async onLogout() {
    await logout();
    this.setState({ user: null });
  }

}

export default App;
