import bind from 'bind-decorator';
import classNames from 'classnames';
import React from 'react';
import { LinkProps, RouteComponentProps, withRouter } from 'react-router-dom';
import './TrButton.scss';


type Diff<T, U extends keyof T> = ({ [P in keyof T]: P } & { [P in U]: never } & { [x: string]: never })[keyof T];
type Omit<T, K extends keyof T> = Pick<T, Diff<T, K>>;

type TrProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>;

export interface TrButtonProps extends Omit<TrProps, 'onClick' | 'ref'> {
  onClick: (button: TrButton) => void;
  innerRef?: React.Ref<HTMLTableRowElement>;
}

export default class TrButton extends React.PureComponent<TrButtonProps> {
  public render() {
    const { className, children, innerRef, role = 'button', tabIndex = 0, onClick, ...props } = this.props;

    return <tr className={classNames(['TrButton', className])} {...props} role={role} children={children} onClick={this.onClick} onKeyPress={this.onKeyPress} ref={innerRef} tabIndex={tabIndex} />;
  }

  @bind
  private onClick() {
    const { onClick } = this.props;
    onClick(this);
  }

  @bind
  private onKeyPress(e: React.KeyboardEvent<HTMLTableRowElement>) {
    if (e.key === 'Enter') {
      this.onClick();
    }
  }
}

type TrLinkProps = Omit<LinkProps, 'innerRef'> & Omit<TrButtonProps, 'onClick'> & RouteComponentProps<void> & {
  navigate?: boolean;
};

class TrLink extends React.PureComponent<TrLinkProps> {
  public render() {
    const { to, replace, onClick, history, location, match, staticContext, navigate, ...props } = this.props;
    return <TrButton {...props} onClick={this.onClick} />;
  }

  @bind
  private onClick() {
    const { history, to, replace, navigate } = this.props;
    if (navigate && typeof to === 'string') {
      window.location.href = to;
    } else if (replace) {
      history.replace(to as any);
    } else {
      history.push(to as any);
    }
  }
}

const TrLinkWithRouter = withRouter(TrLink);
export { TrLinkWithRouter as TrLink };

