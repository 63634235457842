import bind from 'bind-decorator';
import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Link, NavLink as NL } from 'react-router-dom';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { isInRole, Role } from '../api';
import { ReactComponent as Logo } from '../images/IPFX.svg';
import VideoFx from '../images/VideoFX.png';
import './NavMenu.scss';
import { UserConsumer } from './UserContext';

interface State {
  isOpen: boolean
}

export default class NavMenu extends React.Component<{}, State> {
  public readonly state: State = { isOpen: false }

  public render() {
    return <UserConsumer>{user =>
      user && <div className='NavMenu'>
        <Navbar expand='sm' dark className='bg-dark' fixed='top'>
          <NavbarBrand tag={Link} to='/' className='logo'>
            <Logo />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleNavBar} />
          <Collapse isOpen={this.state.isOpen} navbar onClick={this.hideNavBar}>
            <Nav navbar>
              <NavItem>
                <NavLink tag={NL} to={'/'} exact>
                  <FontAwesome name='home' /> Home
                </NavLink>
              </NavItem>
              {isInRole(user, Role.admin) && <NavItem>
                <NavLink tag={NL} to={'/users'}>
                  <FontAwesome name='users' /> User List
                </NavLink>
              </NavItem>}
              <NavItem>
                <NavLink tag={NL} to={'/downloads/software/'}>
                  <FontAwesome name='compact-disc' /> Software Downloads
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={NL} to={'/downloads/manuals/'}>
                  <FontAwesome name='book' /> Manuals
                </NavLink>
              </NavItem>
              {isInRole(user, Role.admin) && <NavItem>
                <NavLink tag={NL} to={'/downloads/e-learning/'}>
                  <FontAwesome name='graduation-cap' /> E-Learning
                </NavLink>
              </NavItem>}
              <NavItem>
                <NavLink tag={NL} to={'/support'}>
                  <FontAwesome name='user' /> Support
                </NavLink>
              </NavItem>
              {isInRole(user, Role.blogger) && <NavItem>
                <NavLink tag={NL} to={'/post/add'}>
                  <FontAwesome name='newspaper' /> Add Post
                </NavLink>
              </NavItem>}
              {isInRole(user, Role.internalUser) && <>
                 <NavItem className="videoFxBorder">
                  <img className='videoFx' src={VideoFx} alt='videoFx' />
                </NavItem>
                <NavItem>
                  <NavLink tag={NL} to={`/orders/`}>
                    <FontAwesome name='shopping-cart' /> VideoFX Orders
                  </NavLink>
                </NavItem>
              </>}
            </Nav>
          </Collapse>
        </Navbar>
      </div>}
    </UserConsumer>;
  }

  @bind
  private toggleNavBar() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  @bind
  private hideNavBar() {
    this.setState({ isOpen: false });
  }
}
