import bind from 'bind-decorator';
import React from 'react';
import { Link, Route, RouteComponentProps } from 'react-router-dom';
import { Button, Modal, ModalBody, Table } from 'reactstrap';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { getAllOrders, Id, isInRole, Order, Role } from '../api';
import OrderPopup from './OrderPopup';
import './Orders.scss';
import Spinner from './Spinner';
import { TrLink } from './TrButton';
import { UserConsumer } from './UserContext';

interface State {
  orders?: Order[];
}

interface RouteProps {
  id?: string | 'new';
}

export default class Orders extends React.PureComponent<RouteComponentProps<RouteProps>, State> {

  public readonly state : State = {  }

  public async componentDidMount() {
    this.setState({
      orders: await getAllOrders()
    });
  }

  public render() {
    const { orders } = this.state;
    return <UserConsumer>
      {user => user && <div className='Orders'>
        <header>Orders List</header>
        <Button className='new-order' tag={Link} to={this.getUrl('new')} color='primary'>New</Button>
        <Table hover>
          <thead>
            <tr>
              {isInRole(user, Role.approver) && <>
                <th>Created By</th>
                <th>Approved By</th>
              </>}
              <th>Hardware ID</th>
              <th>Customer</th>
              <th>Room Name</th>
              <th>Room Location</th>
              <th>Purchase ID</th>
            </tr>
          </thead>
          <tbody>{orders === undefined ?
            <tr>
              <td colSpan={3}><Spinner /></td>
            </tr> :
            orders.map(order =>
              <TrLink key={order.id} replace to={this.getUrl(order.id)}>
                {isInRole(user, Role.approver) && <>
                  <td>{order.creator}</td>
                  <td>{order.approver}</td>
                </>}
                <td>{order.hardwareId || 'n/a'}</td>
                <td>{order.customer}</td>
                <td>{order.roomName}</td>
                <td>{order.roomLocation || 'n/a'}</td>
                <td>{order.purchaseId}</td>
              </TrLink>)}
          </tbody>
        </Table>
        <Route path='/orders/:id' render={this.renderPopup} />
      </div>}
    </UserConsumer>
  }

  private getUrl(id?: Id) {
    if (id === 'new') {
      return '/orders/edit/new';
    }
    return `/orders/${id || ''}`;
  }

  @bind
  private renderPopup(props: RouteComponentProps<RouteProps>) {
    const id = this.getId();
    return <Modal isOpen onClosed={this.hidePopup}>
      <ModalHeader toggle={this.hidePopup}> Customer Order Details</ModalHeader>
      <ModalBody>
        <OrderPopup id={id} onChanged={this.onChanged} />
      </ModalBody>
    </Modal>;
  }

  @bind
  private getId() {
    const { id } = this.props.match.params;
    return id === 'new' ? 0 : Number(id);
  }

  @bind
  private hidePopup() {
    this.props.history.replace(this.getUrl());
  }

  @bind
  private async onChanged() {
    await this.refresh();
    this.hidePopup();
  }

  @bind
  private async refresh() {
    this.setState({
      orders: await getAllOrders(),
    });
  }
}
