import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getImageUrl, getPost, Post } from '../api';
import './PostArticle.scss';

interface NavParams {
  id: string;
}

export default class PostArticle extends React.PureComponent<RouteComponentProps<NavParams>> {
  public readonly state: Post = {
    id: this.getId(),
    title: '',
    content: ''
  }

  public async componentWillMount() {
    const { id } = this.state;
    if (id) {
      this.setState({
        ...await getPost(id)
      });
    }
  }

  public render() {
    const { id, title, content } = this.state;
    return (
      <div className="PostArticle">
        <header>{title}</header>
        <img alt="" className="banner" src={getImageUrl(id)} />
        <p>{content}</p>
      </div>
    );
  }

  private getId() {
    const { id } = this.props.match.params;
    return Number(id);
  }
}
