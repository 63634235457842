import React from 'react';
import { Link } from 'react-router-dom';
import './Breadcrumbs.scss';

interface Props {
  url: string;
  baseUrl: string;
  inSearch: boolean;
}

interface Breadcrumb {
  item: string;
  path: string;
}

export default function Breadcrumbs({ url, baseUrl, inSearch }: Props) {
  if (url.endsWith('/')) {
    url = url.substr(0, url.length - 1);
  }

  if (inSearch) {
    if (url) { url += '/' }
    url += 'search results';
  }

  let breadcrumbs = [{ path: baseUrl, item: 'root' }];

  if (url) {
    let next = baseUrl;
    breadcrumbs = url.split('/').reduce<Breadcrumb[]>((items, item) => {
      next = next + item + '/'
      return [...items, { path: next, item }];
    }, breadcrumbs);
  }

  return (
    <ul className="Breadcrumbs">
      {breadcrumbs.map(({ path, item }, i) =>
        <li key={path}>
          {i === breadcrumbs.length - 1 ?
            <span>{item}</span> :
            <Link to={path}>{item}</Link>
          }
        </li>
      )}
    </ul>
  );
}

