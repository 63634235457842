import bind from 'bind-decorator';
import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { login, UserInfo } from '../api';
import { ReactComponent as Logo } from '../images/IPFX.svg';
import './Login.scss';


interface Props {
  onLogin: (info: UserInfo) => void;
}

interface State {
  email: string;
  password: string;
  sent: boolean;
  showPassword: boolean;
  error: boolean;
}

export default class Login extends React.PureComponent<Props, State> {
  public readonly state: State = {
    password: '',
    email: '',

    error: false,
    sent: false,
    showPassword: false,
  };

  public render() {
    const { email, password, sent, showPassword, error } = this.state;

    return (

      <Col sm={12} className='Login'>
        <Row>
          <Col sm="12" md={{ size: 10, offset: 1 }}>
            <div className='logo'>
              <Logo />
            </div>
            <header className='title'>Sign In to My IPFX Reseller Account</header>
            <p>Welcome to the IPFX Reseller Portal.  You must be an <strong>Authorized IPFX Reseller or Distributor</strong> to access the content. Learn more about how to
            <a href="http://www.ipfx.com/ipfx-reseller-registration/"> become a reseller</a>.</p>
            <p>If you do not have an IPFX Reseller Account, <a href="http://www.ipfx.com/ipfx-reseller-registration/">sign up here</a>.</p>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md={{ size: 4, offset: 4 }}>
            <Form onSubmit={this.onLogin} className="form">
              <header className='header'>Please Log on</header>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FontAwesome name='user' />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input autoComplete="username" name='email' placeholder="email" value={email} onChange={this.onChange} />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <FontAwesome name='lock' />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input autoComplete="current-password" name='password' type={showPassword ? 'text' : 'password'} value={password} onChange={this.onChange} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText onClick={this.onTogglePassword}>
                      <FontAwesome name={showPassword ? 'eye' : 'eye-slash'} />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Button block type='submit' disabled={sent} color="primary" >
                  <FontAwesome name='sign-in-alt' /> Login
                </Button>
              </FormGroup>
              <FormGroup>
                <Link to={`/password/reset`}>Forgot Password</Link>
              </FormGroup>
              {error && <div className='error'>Invalid user name or password</div>}
            </Form>
          </Col>
        </Row>
      </Col>
    );
  }

  @bind
  private onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.currentTarget;
    this.setState({ [name]: value } as any);
  };

  @bind
  private onTogglePassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  @bind
  private async onLogin(e: React.FormEvent<any>) {
    e.preventDefault();
    const { email, password } = this.state;

    this.setState({ sent: true, error: false });

    const info = await login(email, password);

    this.setState({ sent: false, error: info === null });

    if (info) {
      this.props.onLogin(info);
    }
  }
}

