import React from 'react';
import FilesList from '../containers/FilesList';
import { UserConsumer } from './UserContext';
import { isInRole, Role } from '../api';

export default function ELearning() {
  return <UserConsumer>
    {user => user && isInRole(user, Role.admin) && <FilesList rootDir='elearning' title='E-Learning' />}
  </UserConsumer>
}
