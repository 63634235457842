import React from 'react';
import { Col, Row } from 'reactstrap';
import Container from 'reactstrap/lib/Container';
import './Layout.scss';
import NavMenu from './NavMenu';

interface Props {
  children?: React.ReactNode;
}

export default function Layout({ children }: Props) {
  return <Container fluid>
    <Row>
      <Col sm={3}>
        <NavMenu />
      </Col>
      <Col sm={9} className='p-0'>
        <main>
          {children}
        </main>
      </Col>
    </Row>
  </Container>
}
