import bind from 'bind-decorator';
import React, { PureComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import { getOrder, isInRole, OrderData, Role, updateOrder } from '../api';
import './Order.scss';
import Toolbar from './Toolbar';
import { UserConsumer } from './UserContext';

interface Product {
  name: string;
  img: string;
  requiredFeatures: Feature[];
  optionalFeatures: Feature[];
}

interface Feature {
  code: string;
  name: string;
  img: string;
}

const allFeatures: { [index: string]: { code: string, name: string, img: string } } = {
  branding: {
    code: 'VFX-AF-38',
    name: 'Customer Branding',
    img: require('../images/license-tool/videofx_branding.png'),
  },
  callRecording: {
    code: 'VFX-AF-36',
    name: 'Call Recording',
    img: require('../images/license-tool/videofx_recording.png'),
  },
  roomAutomation: {
    code: 'VFX-AF-40',
    name: 'Room Automation',
    img: require('../images/license-tool/videofx_room-control.png'),
  },
  pcScreenSharing: {
    code: 'VFX-AF-39',
    name: 'Screen Sharing for PC',
    img: require('../images/license-tool/videofx-wifi.png'),
  },
  mobileScreenSharing: {
    code: 'VFX-AF-45',
    name: 'Screen Sharing for Mobile',
    img: require('../images/license-tool/mobile.png'),
  },
  displayManagement: {
    code: 'VFX-AF-37',
    name: 'Enhanced Display Management',
    img: require('../images/license-tool/videodisplaymanagement.png'),
  },
  external: {
    code: 'VFX-AF-44',
    name: 'VideoFX External Conferencing Support',
    img: require('../images/license-tool/videofx_involvement.png'),
  },
  bookit: {
    code: 'VFX-AF-41',
    name: 'Bookit',
    img: require('../images/license-tool/Book-It.png'),
  },
  brainstorm: {
    code: 'VFX-AF-43',
    name: 'Brainstorm',
    img: require('../images/license-tool/videofx_brainstorm.png'),
  },
};

const allProducts: { [index: string]: { name: string, img: string, requiredFeatures: Feature[], optionalFeatures: Feature[] } } = {
  'VFX-CS-01': {
    name: 'VideoFX Video Conferencing',
    img: require('../images/license-tool/videofx_vc-express-300x300.jpg'),
    requiredFeatures: [],
    optionalFeatures: [
      allFeatures.branding, allFeatures.callRecording, allFeatures.roomAutomation,
      allFeatures.pcScreenSharing, allFeatures.bookit
    ],
  } as Product,
  'VFX-CS-02': {
    name: 'VideoFX Video Collaboration',
    img: require('../images/license-tool/videofx_vc-plus-300x300.jpg'),
    requiredFeatures: [allFeatures.branding],
    optionalFeatures: [
      allFeatures.callRecording, allFeatures.roomAutomation, allFeatures.pcScreenSharing,
      allFeatures.mobileScreenSharing, allFeatures.displayManagement, allFeatures.bookit
    ],
  } as Product,
  'VFX-CS-03': {
    name: 'VideoFX All-in-One Collaboration Express',
    img: require('../images/license-tool/videofx_collab-enhanced-300x300.jpg'),
    requiredFeatures: [allFeatures.branding],
    optionalFeatures: [
      allFeatures.callRecording, allFeatures.roomAutomation, allFeatures.pcScreenSharing,
      allFeatures.mobileScreenSharing, allFeatures.displayManagement, allFeatures.bookit
    ],
  } as Product,
  'VFX-CS-04': {
    name: 'VideoFX All-in-One Collaboration Professional',
    img: require('../images/license-tool/videofx_collab-pro-300x300.jpg'),
    requiredFeatures: [allFeatures.branding],
    optionalFeatures: [
      allFeatures.callRecording, allFeatures.roomAutomation, allFeatures.pcScreenSharing,
      allFeatures.mobileScreenSharing, allFeatures.displayManagement, allFeatures.bookit,
      allFeatures.external, allFeatures.brainstorm
    ],
  } as Product,
};

function getFeature(featureName: string) {
  return allFeatures[featureName] as Feature;
}

interface ProductButtonProps {
  productName: string;
  selected: boolean,
  onSelect: (productName: string) => void;
}

class ProductButton extends PureComponent<ProductButtonProps> {
  public render() {
    const { productName, selected } = this.props;
    const product = allProducts[productName] as Product;

    return (
      <Col xs={6} lg={3}>
        <label>
          <input type='radio' name='product' onChange={this.onChange} checked={selected} />
          <img src={product.img} />
          <span>{product.name}</span>
        </label>
      </Col>
    );
  }

  @bind
  private onChange(e: any) {
    const { productName, onSelect } = this.props;
    onSelect(productName);
  }
}

interface FeatureButtonProps {
  productName: string;
  featureName: string;
  selected: boolean,
  onSelect: (featureName: string, select: boolean) => void;
}

class FeatureButton extends PureComponent<FeatureButtonProps> {
  public render() {
    const { productName, featureName, selected } = this.props;
    const product = allProducts[productName] as Product;
    const feature = getFeature(featureName);

    const isRequired = product.requiredFeatures.indexOf(feature) !== -1;
    const isOptional = product.optionalFeatures.indexOf(feature) !== -1;

    if (!isRequired && !isOptional) {
      return null;
    }

    return (
      <Col xs={4} sm={3} lg={2}>
        <label>
          <input type='checkbox' disabled={isRequired} checked={selected} onChange={this.onChange} />
          <img src={feature.img} />
          <span>{feature.name}</span>
        </label>
      </Col>
    );
  }

  @bind
  private onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { featureName, onSelect } = this.props;
    if (onSelect) {
      onSelect(featureName, e.target.checked);
    }
  }
}

interface State {
  product: string | null;
  features: string[];

  customer: string;
  roomName: string;
  roomLocation: string | null;
  purchaseId: string;
  reseller: string;
  expiryPeriod: number | null;
  hardwareId: string;

  creator?: string;
  approver?: string | null;
}

interface NavParams {
  id?: string | 'new';
}

function getEmptyState(): State {
  return {
    product: null,
    features: [],

    customer: '',
    expiryPeriod: null,
    hardwareId: '',
    purchaseId: '',
    reseller: '',
    roomName: '',
    roomLocation: '',
  };
}

export default class Order extends React.PureComponent<RouteComponentProps<NavParams>, State> {
  public readonly state: State = getEmptyState();

  public async componentDidMount() {
    const id = this.getId();
    if (id) {
      const { product, customer, expiryPeriod, hardwareId, purchaseId, reseller, roomName, roomLocation, features, approver, creator } = await getOrder(id);
      this.setState({
        product,
        customer,
        expiryPeriod,
        hardwareId: hardwareId || '',
        purchaseId,
        reseller,
        roomName,
        roomLocation,
        features: features.map(c => Object.keys(allFeatures).filter(f => getFeature(f).code === c)[0]),
        creator,
        approver,
      });
    }
  }

  public render() {
    const { product, features, approver, creator, expiryPeriod } = this.state;
    const isNew = this.getId() === 0;

    return <UserConsumer>
      {user => user && <div className='Order'>
        <header>Order VideoFX</header>
        <hr />
        {isInRole(user, Role.approver) && !isNew && <div>
          <label key=''>Created By:</label>{' '}{creator}
        </div>}
        {approver && <div>
          <label key=''>Approved By:</label>{' '}{approver}
        </div>}
        <h2>Customer Order Details</h2>
        <Form onSubmit={this.onSubmit}>
          <Row>
            {this.renderField('customer', 'Customer Name')}
            <Col xs={6}>
              <Row>
                {this.renderField('roomName', 'Room Name')}
                {this.renderField('roomLocation', 'Room Location', false)}
              </Row>
            </Col>
            {this.renderField('purchaseId', 'Purchase ID')}
            {this.renderField('reseller', 'Reseller Name')}
            <Col xs={6}>
              <Row>
                <Col xs={3}>
                  <FormGroup className='expiryPeriod'>
                    <Label>NFR / pilot</Label>
                    <Input type="checkbox" name='expiryPeriod' checked={expiryPeriod !== null} onChange={this.onExpiryPeriodChecked} />
                  </FormGroup>
                </Col>
                <Col xs={9}>
                  <FormGroup>
                    <Label children='&#xa0;' />
                    <Input type='select' value={expiryPeriod || ''} onChange={this.onExpiryPeriodChanged} disabled={expiryPeriod === null}>
                      {expiryPeriod === null && <option value='' />}
                      <option value={30}>30 Days</option>
                      <option value={180}>180 Days</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            {this.renderField('hardwareId', 'Hardware ID', true, () => <InputGroup>
              {this.bindInput('hardwareId', <Input required />)}
              <InputGroupAddon addonType="append">
                <Button href='/VideoFX Hardware ID.exe'>Generate</Button>
              </InputGroupAddon>
            </InputGroup>)}
          </Row>
          <hr />
          <h2>VideoFX Product</h2>
          <Row className='products'>
            {Object.keys(allProducts).map(p => <ProductButton key={p} productName={p} selected={p === product} onSelect={this.onSelectProduct} />)}
          </Row>
          {product && <>
            <hr />
            <h2>VideoFX Features</h2>
            <Row className='features'>
              {Object.keys(allFeatures).map(f => <FeatureButton key={f} productName={product} featureName={f} selected={features.includes(f)} onSelect={this.onSelectFeature} />)}
            </Row>
          </>}
          {product && <hr />}
          <Row>
            <Col xs={6}>
              <Toolbar>
                {(isNew || !approver || isInRole(user, Role.approver)) && <Button color='primary' size="sm" type='submit'>{isNew ? 'Order VideoFX' : 'Save'}</Button>}{' '}
                {isNew && <Button color="primary" outline size="sm" onClick={this.onReset}>Clear</Button>}
              </Toolbar>
            </Col>
          </Row>
        </Form>
      </div>
      }
    </UserConsumer>;
  }

  private renderField(name: keyof State, label: string, required: boolean = true, createInput: (() => any) | null = null) {
    return (
      <Col xs={6}>
        <FormGroup>
          <Label className={required ? undefined : 'optional'}>{label}</Label>
          {createInput ? createInput() : this.bindInput(name, <Input required={required} />)}
        </FormGroup>
      </Col>
    );
  }

  private bindInput(name: keyof State, input: JSX.Element) {
    return React.cloneElement(input, {
      onChange: this.onInputChanged,
      value: this.state[name],
      name,
    });
  }
  private getId() {
    const { id } = this.props.match.params;
    return id === 'new' ? 0 : Number(id);
  }

  @bind
  private onExpiryPeriodChecked(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      expiryPeriod: e.target.checked ? 30 : null
    });
  }

  @bind
  private onExpiryPeriodChanged(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ expiryPeriod: parseInt(e.target.value, 10) });
  }

  @bind
  private onInputChanged(e: React.ChangeEvent<Input & HTMLInputElement>) {
    this.setState({
      [e.target.name]: e.target.value
    } as any);
  }

  @bind
  private onSelectProduct(productName: string) {
    const product = allProducts[productName] as Product;
    const { features } = this.state;
    this.setState({
      features: [
        ...Object.keys(allFeatures).filter(f => product.requiredFeatures.includes(getFeature(f))),
        ...features.filter(f => product.optionalFeatures.includes(getFeature(f))),
      ],
      product: productName,
    });
  }

  @bind
  private onSelectFeature(featureName: string, select: boolean) {
    const features = [...this.state.features];

    if (select) {
      features.push(featureName);
    } else {
      const index = features.indexOf(featureName);
      if (index !== -1) {
        features.splice(index, 1);
      }
    }
    this.setState({ features });
  }

  @bind
  private async onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const id = this.getId();

    const { product, features, customer, expiryPeriod, hardwareId, purchaseId, reseller, roomName, roomLocation } = this.state;
    if (product === null) {
      return;
    }
    const order: OrderData = {
      id,
      product,
      customer,
      expiryPeriod,
      hardwareId: orNull(hardwareId),
      purchaseId,
      reseller,
      roomName,
      roomLocation,
      features: features.map(f => getFeature(f).code)
    };
    const newId = await updateOrder(order);
    this.props.history.replace(`/orders/${newId}`);
  }

  @bind
  private onReset() {
    this.setState(getEmptyState());
  }
}

function orNull(str: string) {
  return str === '' ? null : str;
}
