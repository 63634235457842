import React from 'react';
import { Link } from 'react-router-dom';
import EnquiryIco from '../images/inquiry.png';
import SupportIco from '../images/man-headset.png';
import './Support.scss';

declare var IPFXStartWebChat: any;

export default class Support extends React.PureComponent {
  public render() {
    return (
      <div className="Support">
        <header>Support</header>
        <div className='media'>
          <button className='media-box' onClick={this.showChat}>
            <img src={SupportIco} alt="support" />
            <h5 className="icon-title">Live Chat</h5>
            <p>Have a live chat with one of our agents</p>
            <span className="red">Start Live Chat</span>
          </button>
          <Link to='/support/enquiry' className='media-box'>
            <img src={EnquiryIco} alt="enquiry" />
            <h5 className="icon-title">Enquiry Form</h5>
            <p>Reseller Enquiry Form</p>
            <span className="red">More Info</span>
          </Link>
        </div>
      </div>
    );
  }

  private showChat() {
    return IPFXStartWebChat('https://mobility-nz.ipfx.com/wc');
  }
}



