const apiUrl = '/api';

export function getUrl(url: string) {
  return `${apiUrl}/${url}`;
}

async function fetchJson<T>(url: string, init: RequestInit) {
  const response = await fetch(getUrl(url), {
    credentials: 'include',
    ...init,
  });
  if (response.status === 204) {
    return undefined! as T;
  }
  if (response.status >= 400) {
    let body = await response.text();
    try {
      body = (await JSON.parse(body)).message;
    } catch (e) {
      ;
    }
    throw new HttpError(response.status, response.statusText, body);
  }
  return await response.json() as T;
}

async function get<T>(url: string) {
  return await fetchJson<T>(url, {});
}

async function post<T>(url: string, body?: any) {
  return await fetchJson<T>(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: body === undefined ? undefined : JSON.stringify(body)
  });
}

async function postForm<T>(url: string, params: any) {
  const body = new FormData();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      body.append(key, params[key]);
    }
  }
  return await fetchJson<T>(url, {
    method: 'POST',
    body
  });
}

async function del(url: string, body?: any) {
  return fetchJson(url, {
    body: body === undefined ? undefined : JSON.stringify(body),
    method: 'DELETE'
  });
}

export class HttpError extends Error {
  public readonly status: number;
  public readonly statusText: string;
  public readonly body: string;

  constructor(status: number, statusText: string, body: string) {
    super(`${status} ${statusText}`);

    this.status = status;
    this.statusText = statusText;
    this.body = body;

    Object.setPrototypeOf(this, HttpError.prototype);
  }
}

export type Id = number | 'new';

export interface User {
  id: number,
  firstName: string,
  lastName: string,
  company: string,
  email: string,
  password: string,
}

export async function getUser(id: number) {
  return await get<User>(`user/${id}`);
}

export async function getAllUsers() {
  return await get<User[]>('user');
}

export async function updateUser(user: User) {
  return await post<number>(`user`, user);
}

export async function removeUser(id: number) {
  return await del(`user/${id}`);
}

export async function whoAmI() {
  try {
    return await get<UserInfo>('auth/whoami')
  } catch (ex) {
    if (ex instanceof HttpError && ex.status === 401) {
      return null;
    }
    throw ex;
  }
}

export async function login(email: string, password: string) {
  try {
    return await post<UserInfo>('auth/login', { email, password })
  } catch (ex) {
    if (ex instanceof HttpError && ex.status === 401) {
      return null;
    }
    throw ex;
  }
}

export async function logout() {
  return await post<{}>('auth/logout');
}

export enum Role {
  admin = "admin",
  blogger = "blogger",
  approver = "approver",
  internalUser = "internalUser",
};

export interface UserInfo {
  name: string;
  email: string;
  roles: Role[];
}

export function isInRole(user: UserInfo, role: Role) {
  return user.roles.includes(role);
}

export interface DirEntry {
  fullName: string;
  name: string;
}

export interface FileEntry {
  fullName: string;
  name: string;
  lastModified: Date;
  size: number;
}

export interface Entries {
  dirs: DirEntry[],
  files: FileEntry[],
}

export interface Enquiry {
  enquiryType: string;
  message: string;
}

export async function sendEnquiry(enquiry: Enquiry) {
  await post('enquiry', enquiry);
}

export async function getFiles(path: string) {
  return await get<Entries>(`file/${path}`)
}

export async function createLink(fullName: string) {
  return await post<string>('file/link', fullName);
}

export async function searchFiles(path: string, searchKey: string) {
  return await post<Entries>('file/search', { path, searchKey })
}

export interface Post {
  id: number,
  title: string;
  content: string;
  thumbnail?: File;
}

export function getImageUrl(id: number) {
  return getUrl(`post/image/${id}`);
}

export async function getLatestPosts() {
  return await get<Post[]>('post/latest');
}

export async function addPost(newPost: Post) {
  return await postForm<number>(`post`, newPost)
}

export async function getPost(id: number) {
  return await get<Post>(`post/${id}`)
}

export interface Token {
  user: User
}

export async function requestResetLink(email: string) {
  await post('auth/reset', email);
}

export async function saveNewPassword(password: string) {
  await post('auth/password', password);
}

export interface Order extends OrderData {
  creator: string;
  approver: string | null;
}

export interface OrderData {
  id: number;
  product: string;
  features: string[];

  customer: string;
  roomName: string;
  roomLocation: string | null;
  purchaseId: string;
  reseller: string;
  expiryPeriod: number | null;
  hardwareId: string | null;
}

export async function getAllOrders() {
  return await get<Order[]>('order');
}

export async function getOrder(id: number) {
  return await get<Order>(`order/${id}`);
}

export async function removeOrder(id: number, reason: string) {
  await post(`order/${id}`, reason);
}

export async function updateOrder(order: OrderData) {
  return await post<number>(`order`, order);
}

export async function approve(id: number) {
  await post(`order/approve/${id}`);
}

export async function getLicense(id: number) {
  return await get(`order/license/${id}`);
}
