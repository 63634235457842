
import bind from 'bind-decorator';
import React from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { sendEnquiry } from '../api';
import './Enquiry.scss';
import Spinner from './Spinner';
import Toolbar from './Toolbar';

interface State {
  enquiryType: string;
  message: string;
  sentState: 'unsent' | 'sending' | 'sent';
}

export default class Enquiry extends React.PureComponent<{}, State> {
  public readonly state: State = {
    enquiryType: '',
    message: '',
    sentState: 'unsent'
  };

  public render() {
    const { enquiryType, message, sentState } = this.state;
    const disabled = sentState === 'sending';

    return (
      <div className='Enquiry'>
        <header>Submit Enquiry</header>
        {sentState !== 'sent' ?
          <Form className='form' onSubmit={this.onSubmit} disabled={disabled}>
            <FormGroup>
              <Label for="type">Enquiry Type</Label>
              <Input required type="select" name="enquiryType" value={enquiryType} onChange={this.onChange}>
                <option value="" disabled>Select... </option>
                <option value="General Enquiry">General Enquiry</option>
                <option value="Software Installation">Software Installation</option>
                <option value="Manual Request">Manual Request</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="message">Message</Label>
              <Input required type="textarea" className="message" name="message" value={message} placeholder="Your message here" onChange={this.onChange} />
            </FormGroup>
            <FormGroup>
              <Toolbar>
                <Button size="sm" color="primary" disabled={disabled} type="submit">
                  Submit
                  {disabled && <Spinner />}
                </Button>
                <Button size="sm" color="primary" disabled={disabled} outline onClick={this.clear}>Clear</Button>
              </Toolbar>
            </FormGroup>
          </Form>
          : <div>
            <p className="isSent">Your enquiry is sent. Thank you.</p>
          </div>
        }
      </div>
    );
  }

  @bind
  private clear() {
    this.setState(
      {
        enquiryType: "",
        message: ""
      }
    );
  }

  @bind
  private onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.currentTarget;
    this.setState({ [name]: value } as any);
  };

  @bind
  private async onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const { enquiryType, message } = this.state;

    this.setState({ sentState: 'sending' });
    await sendEnquiry({ enquiryType, message });
    this.setState({ sentState: 'sent' });
  }
}

