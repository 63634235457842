import bind from 'bind-decorator';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { getUser, removeUser, updateUser, User } from '../api';
import Toolbar from './Toolbar';
import './UserForm.scss';
;
interface State extends User {
  confirmPassword: string,
  error: boolean
}

interface NavParams {
  id: string | 'new';
}

type Props = RouteComponentProps<NavParams> & {
  user: User;
}

export default class UserForm extends React.PureComponent<Props, State> {
  public readonly state: State = {
    confirmPassword: '',
    error: false,
    company: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    id: 0,
  };

  public async componentWillMount() {
    const userId = this.getId();
    if (userId) {
      this.setState({
        ...await getUser(userId)
      });
    }
  }

  public render() {
    const { firstName, lastName, company, email, password, confirmPassword, error } = this.state;
    const id = this.getId();
    return (
      <div className="UserForm">
        <header>Portal Access</header>
        <h3>{id === 0 ? 'New User Details' : 'Edit User Details'}</h3>
        <Form onSubmit={this.onSubmit} className='form'>
          <Row form='x'>
            <Col md={6}>
              <FormGroup>
                <Label for="firstName">First Name</Label>
                <Input required type="text" name="firstName" id="firstName" value={firstName} onChange={this.onChange} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="lastName">Last Name</Label>
                <Input required type="text" name="lastName" id="lastName" value={lastName} onChange={this.onChange} />
              </FormGroup>
            </Col>
          </Row>
          <Row form='x'>
            <Col md={6}>
              <FormGroup>
                <Label for="company">Company</Label>
                <Input required type="text" name="company" id="company" value={company} onChange={this.onChange} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input required type="email" name="email" id="email" value={email} onChange={this.onChange} />
              </FormGroup>
            </Col>
          </Row>
          <Row form='x'>
            <Col md={6}>
              <FormGroup>
                <Label for="password">Password</Label>
                <Input type="password" name="password" id="password" autoComplete='new-password' value={password} onChange={this.onChange} />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="confirmPassword">Confirm Password</Label>
                <Input type="password" name="confirmPassword" id="confirmPassword" autoComplete='new-password' value={confirmPassword} onChange={this.onChange} />
              </FormGroup>
            </Col>
          </Row>
          <Toolbar>
            <Button color="primary" size="sm" type="submit">Save User</Button>{' '}
            {id !== 0 && <Button size="sm" color='danger' type="submit" onClick={this.onDelete}>Remove</Button>}{' '}
            <Button color="primary" outline size="sm" onClick={this.onClear}>Clear</Button>
          </Toolbar>
        </Form>
        {error && <div className='userform-error'>Passwords should match</div>}
      </div >
    );
  }

  @bind
  private async onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const { firstName, lastName, company, email, password, confirmPassword } = this.state;

    if (password !== confirmPassword) {
      this.setState({ error: true });
      return;
    } else {
      this.setState({ error: false });
    }

    await updateUser({
      id: this.getId(),
      firstName,
      lastName,
      company,
      email,
      password,
    });

    this.goBack();
  }

  private goBack() {
    const { history } = this.props;
    history.replace(`/users`);
  }

  private getId() {
    const { id } = this.props.match.params;
    return id === 'new' ? 0 : Number(id);
  }

  @bind
  private onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.currentTarget;
    this.setState({ [name]: value } as any);
  };

  @bind
  private onClear() {
    this.setState({
      firstName: '',
      lastName: '',
      company: '',
      email: '',
      password: '',
      confirmPassword: '',
      error: false
    });
  }

  @bind
  private async onDelete(e: React.FormEvent<HTMLButtonElement>) {
    if (confirm("Are you sure you want to delete?")) {
      await removeUser(this.getId());
      this.goBack();
    }
  }
}
