import bind from 'bind-decorator';
import copyToClipboard from 'clipboard-copy';
import formatDate from 'date-fns/format';
import filesize from 'filesize';
import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Button, Input, Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import { DirEntry, Entries, FileEntry } from '../api';
import './FilesList.scss';
import Spinner from './Spinner';
import { ShowToast, Toaster } from './Toaster';
import { TrLink } from './TrButton';

interface DirProps {
  dir: DirEntry;
  baseUrl: string;
  fullName?: boolean;
}

function Dir({ dir, fullName, baseUrl }: DirProps) {
  let to = baseUrl + dir.fullName;
  if (!to.endsWith('/')) { to += '/' }

  return (
    <TrLink className="entry" to={to} >
      <td colSpan={4}>
        <FontAwesome className='icon' name='folder-open' />
        {fullName ? dir.fullName : dir.name}
      </td>
    </TrLink>
  );
}

export interface FileProps {
  file: FileEntry;
  rootDir: string;
  fullName?: boolean;
  onCreateLink(): void;
}

export function File({ file, fullName, onCreateLink, rootDir }: FileProps) {
  return (
    <TrLink className="entry file" to={`/api/file/${rootDir}/${file.fullName}`} navigate>
      <td>
        <FontAwesome className='icon' name='file' />
        {fullName ? file.fullName : file.name}
      </td>
      <td className="right" title={`${file.size} B`}>{filesize(file.size)}</td>
      <td className="right">{formatDate(file.lastModified, 'DD/MM/YYYY hh:mm:ss A')}</td>
      <td className="right">
        <Button outline size='sm' onClick={createLink.bind(null, onCreateLink)}>
          <FontAwesome name='link' />{' '}Create Link
        </Button>
      </td>
    </TrLink>
  );

  function createLink(cb: () => void, e: React.SyntheticEvent) {
    e.stopPropagation();
    cb();
  }
}

interface Props {
  title: string;
  entries?: Entries;
  children?: React.ReactNode;
  fullName?: boolean;
  baseUrl: string;
  rootDir: string;
  createLink(fullName: string): Promise<string>;
}

interface State {
  link?: string;
}

export default class FilesList extends React.PureComponent<Props, State> {
  public readonly state: State = {};

  public render() {
    const { children, title, entries, fullName, baseUrl, rootDir } = this.props;
    const { link } = this.state;
    return (
      <div className="FilesList" >
        <header>{title}</header>
        {children}
        <Table hover>
          <thead>
            <tr>
              <th>File</th>
              <th className="right">Size</th>
              <th className="right">Last Modified</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {!entries ? <tr>
              <td colSpan={3}><Spinner /></td>
            </tr> : <>
                {entries.dirs.map(d => <Dir baseUrl={baseUrl} fullName={fullName} key={d.fullName} dir={d} />)}
                <Toaster>{toaster =>
                  entries.files.map(f => <File rootDir={rootDir} fullName={fullName} key={f.fullName} file={f} onCreateLink={() => this.onCreateLink(f, toaster.showToast)} />)
                }</Toaster>
              </>}
          </tbody>
        </Table>
        <Modal isOpen={link !== undefined} toggle={this.onCloseModal}>
          <ModalHeader toggle={this.onCloseModal}>
            The link will remain valid for 48 hours
          </ModalHeader>
          <ModalBody>
            <Input defaultValue={link} />
          </ModalBody>
        </Modal>
      </div>
    );
  }

  @bind
  private async onCreateLink(file: FileEntry, showToast: ShowToast) {
    let link: string | undefined;
    const { createLink, rootDir } = this.props;
    try {
      link = await createLink(`${rootDir}/${file.fullName}`);
      await copyToClipboard(link);
      showToast(<>The 24-hr valid <a target='_blank' href={link}>link</a> is copied to clipboard.</>, {
        className: 'FilesListToast'
      });
    } catch (e) {
      if (link) {
        this.setState({ link });
      } else {
        showToast(`Error creating a link: ${e}`, { color: 'danger' });
      }
    }
  }

  @bind
  private onCloseModal() {
    this.setState({ link: undefined });
  }
}
