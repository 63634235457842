import { parse } from 'query-string';
import React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import resolvePathname from 'resolve-pathname';
import { createLink, Entries, getFiles, searchFiles } from '../api';
import Breadcrumbs from '../components/Breadcrumbs';
import FilesList from '../components/FilesList';
import SearchBar from '../components/SearchBar';

function nameof<T>(name: Extract<keyof T, string>): string { return name }

interface NavParams {
  path?: string;
}

type Props = RouteComponentProps<NavParams> & {
  rootDir: string;
  title: string;
}

interface State {
  entries?: Entries;
}

class FilesListContainer extends React.PureComponent<Props, State> {
  public readonly state: State = {}

  public async componentDidMount() {
    this.loadEntries();
  }

  public async componentDidUpdate(prevProps: Props) {
    if (this.getPath() !== this.getPath(prevProps)
      || this.getSearch() !== this.getSearch(prevProps)) {
      this.loadEntries();
    }
  }

  public render() {
    const { entries } = this.state;
    const { location, title, rootDir } = this.props;

    const url = location.pathname;
    if (!url.endsWith('/')) {
      return <Redirect to={`${url}/`} />
    }

    const path = this.getPath();
    const baseUrl = this.getBaseUrl();

    return <FilesList baseUrl={baseUrl} rootDir={rootDir} entries={entries} title={title} createLink={createLink}>
      <Breadcrumbs baseUrl={baseUrl} url={path} inSearch={!!this.getSearch()} />
      <SearchBar />
    </FilesList>;
  }

  private async loadEntries() {
    this.setState({ entries: undefined });

    const path = this.getPath();
    const search = this.getSearch();
    const fullPath = this.props.rootDir + '/' + path;
    let entries;

    if (!search) {
      entries = await getFiles(fullPath);
      if (path) {
        entries.dirs = [{ name: '..', fullName: this.resolveUrl(path + '..') }, ...entries.dirs];
      }
    } else {
      entries = await searchFiles(fullPath, search);
    }

    this.setState({ entries });
  }

  private getPath({ match: { params: { path } } }: Props = this.props) {
    return path ? path + '/' : '';
  }

  private getBaseUrl() {
    return this.props.match.path.replace(`:${nameof<NavParams>("path")}*`, '');
  }

  private resolveUrl(path: string) {
    path = resolvePathname(path);
    if (path.endsWith('/')) {
      path = path.substr(0, path.length - 1);
    }
    return path;
  }

  private getSearch({ location: { search } }: Props = this.props) {
    return parse(search).search as string | undefined;
  }
}

export default withRouter(FilesListContainer);
