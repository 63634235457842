import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { getImageUrl, getLatestPosts, Post } from '../api';
import './PostsList.scss';

interface State {
  postEntries?: Post[];
}

class PostsList extends React.PureComponent<RouteComponentProps<any>, State> {
  public readonly state: State = {};

  public async componentDidMount() {
    this.setState({
      postEntries: await getLatestPosts(),
    });
  }

  public render() {
    const { postEntries } = this.state;
    return (
      <div className='PostsList'>
      <h6>Latest News</h6>
        {postEntries && postEntries.map((post, i) =>
          <Link key={post.id} to={`/post/${post.id}`}>
            <div className="post">
              <div>
                <img alt='' className="preview" src={getImageUrl(post.id)} />
              </div>
              <div>
                <h5>{post.title}</h5>
                <p>{post.content}</p>
              </div>
             </div>
            {i + 1 < postEntries.length && <hr />}
          </Link>
        )}
      </div>
    );
  }
}

export default withRouter(PostsList);
