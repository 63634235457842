import bind from 'bind-decorator';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import { requestResetLink } from '../api';
import { ReactComponent as Logo } from '../images/IPFX.svg';
import './PasswordReset.scss';

interface State {
  email: string;
  sent?: boolean;
}

export default class PasswordReset extends React.PureComponent<{}, State> {
  public readonly state: State = {
    email: '',
  };

  public render() {
    const { email, sent } = this.state;

    return (

      <Col sm={12} className="PasswordReset">
        <Row>
          <Col sm="12" md={{ size: 4, offset: 4 }}>
            <div className='logo'>
              <Logo />
            </div>
            <p className='h2'>Did you forget your password?</p>
            <p className='p'>Enter your email address and we will send you a password reset link.</p>
          </Col>
        </Row>

        <Row>
          <Col sm="12" md={{ size: 4, offset: 4 }}>
            {sent ?
              <p>If the email is valid, you will receive an email with the link to change your password.</p>
              :
              <Form className="form" onSubmit={this.requestResetLink}>
                <FormGroup>
                  <Input className="input" name='email' placeholder="email" value={email} onChange={this.onChange} />
                </FormGroup>
                <FormGroup>
                  <Button block type='submit' disabled={email === ''} color="primary">Request Reset Link</Button>
                </FormGroup>
                <FormGroup> <Link to={`/login`}>Back to Login</Link></FormGroup>
              </Form>
            }
          </Col>
        </Row>
      </Col>
    )
  }

  @bind
  private onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.currentTarget;
    this.setState({ [name]: value } as any);
  };


  @bind
  private async requestResetLink(e: React.FormEvent<any>) {
    e.preventDefault();
    const { email } = this.state;
    await requestResetLink(email);
    this.setState({
      sent: true
    });
  }
}
