import '@fortawesome/fontawesome-free/css/all.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import App from './App';
import './index.scss';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')!;

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
     <Route path='/' component={App} />
  </BrowserRouter>
, document.getElementById('root'));
