import bind from 'bind-decorator';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { approve, getOrder, isInRole, Order, removeOrder, Role } from '../api';
import './OrderPopup.scss';
import OrderRemoval from './OrderRemoval';
import Toolbar from './Toolbar';
import { UserConsumer } from './UserContext';

interface Props {
  id: number;
  onChanged: () => void;
}

interface State {
  order?: Order;
  removing: boolean;
}

export default class OrderPopup extends React.PureComponent<Props, State> {
  public readonly state: State = { removing: false };

  public async componentDidMount() {
    const order = await getOrder(this.props.id);
    order.features.sort();
    this.setState({ order });
  }

  public render() {
    const { order, removing } = this.state;
    if (!order) {
      return null;
    }
    const approved = order.approver !== null;
    return <UserConsumer>
      {user => user && <div className='OrderPopup'>
        <dl>
          <dt>Created By</dt>
          <dd>{order.creator}</dd>

          {approved && <>
            <dt>Approved By</dt>
            <dd>{order.approver}</dd>
          </>}

          <dt>Hardware ID</dt>
          <dd>{order.hardwareId || 'n/a'}</dd>

          <dt>Customer Name</dt>
          <dd>{order.customer}</dd>

          <dt>Room Name</dt>
          <dd>{order.roomName}</dd>

          <dt>Room Location</dt>
          <dd>{order.roomLocation || 'n/a'}</dd>

          <dt>Purchase ID</dt>
          <dd>{order.purchaseId}</dd>

          <dt>Reseller Name</dt>
          <dd>{order.reseller}</dd>

          <dt>NFR / Pilot</dt>
          <dd>{order.expiryPeriod === null ? 'no' : `${order.expiryPeriod} days`}</dd>
        </dl>
        <hr />

        <header>SKUs</header>
        <div className='skus product'>
          <Sku>{order.product}</Sku>
        </div>
        <div className='skus features'>
          {order.features.map(f => <Sku key={f}>{f}</Sku>)}
        </div>

        <hr />

        {!removing && <Toolbar>
          {!approved && isInRole(user, Role.approver) && <Button color='success' size='sm' onClick={this.onApprove}>Approve</Button>}
          {approved && <Button size='sm' tag='a' href={`/api/order/license/${this.props.id}`}>Download License</Button>}
          <Toolbar.PushRight />
          {(!approved || isInRole(user, Role.approver)) && <Link to={`/orders/edit/${order.id}`}>
            <Button size='sm' color='primary'>Edit</Button>
          </Link>}
          <Button size='sm' color='danger' onClick={this.onRemoving}>Remove...</Button>
        </Toolbar>}
        {removing && <OrderRemoval onRemove={this.onRemove} onCancel={this.onCancelRemoving} />}
      </div>
      }
    </UserConsumer>;
  }

  @bind
  private async onApprove() {
    await approve(this.props.id);
    this.props.onChanged();
  }

  @bind
  private async onRemoving() {
    this.setState({ removing: true });
  }

  @bind
  private async onCancelRemoving() {
    this.setState({ removing: false });
  }

  @bind
  private async onRemove(reason: string) {
    await removeOrder(this.props.id, reason);
    this.props.onChanged();
  }
}

function Sku({ children }: { children: string }) {
  return <span className='sku'>{children}</span>;
}
