import React from 'react';
import './Toolbar.scss';

interface Props {
  children: React.ReactNode;
}

export default function Toolbar({ children }: Props) {
  return <div className='Toolbar'>
    {children}
  </div>
}

Toolbar.PushRight = function PushRight() {
  return <div className='PushRight' />
}
