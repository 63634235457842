import bind from 'bind-decorator';
import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Form, Input, Table } from 'reactstrap';
import { throttle } from 'throttle-debounce';
import { getAllUsers, Id, User } from '../api';
import Spinner from './Spinner';
import Toolbar from './Toolbar';
import { TrLink } from './TrButton';
import './UserList.scss';

interface State {
  users?: User[];
  searchKey: string;
}

export default class UserList extends React.PureComponent<RouteComponentProps, State> {
  public readonly state: State = { searchKey: '' };

  public async componentDidMount() {
    this.setState({
      users: await getAllUsers(),
    });
  }

  public render() {
    let { users } = this.state;
    const { searchKey } = this.state;

    if (users && searchKey) {
      users = users.filter((u) => u.company.includes(searchKey) ||
        u.email.includes(searchKey) ||
        u.firstName.includes(searchKey) ||
        u.lastName.includes(searchKey));
    }

    return (
      <div className='UserList'>
        <header>User List</header>
        <Form inline>
          <Toolbar>
            <FilterBar onFilter={this.onFilter} />
            <Button tag={Link} to={this.getUrl('new')} color='primary' disabled={!users}>Add New User</Button>
          </Toolbar>
        </Form>
        <Table hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Company</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>{users === undefined ?
            <tr>
              <td colSpan={3}><Spinner /></td>
            </tr> :
            <>{users.map(user =>
              <TrLink key={user.id} replace to={this.getUrl(user.id)}>
                <td>{user.firstName} {user.lastName}</td>
                <td>{user.company}</td>
                <td>{user.email}</td>
              </TrLink>)
            }</>
          }</tbody>
        </Table>
      </div>
    );
  }

  private getUrl(id: Id) {
    return `${this.props.match.url}/edit/${id}`;
  }

  @bind
  private async onFilter(searchKey: string) {
    this.setState({ searchKey });
  }
}

interface FilterBarProps {
  onFilter(searchKey: string): void;
}

interface FilterBarState {
  value: string;
}

class FilterBar extends React.PureComponent<FilterBarProps, FilterBarState> {
  public readonly state: FilterBarState = { value: '' }
  private readonly filterThrottled = throttle(300, this.onFilter, false);

  public render() {
    return <Input type="search" onChange={this.onChange} value={this.state.value} placeholder="Filter" />;
  }

  public componentWillUnmount() {
    (this.filterThrottled as any).cancel();
  }

  @bind
  private async onChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      value: e.currentTarget.value
    }, () => this.filterThrottled());
  }

  @bind
  private onFilter() {
    this.props.onFilter(this.state.value);
  }
}
