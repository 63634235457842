import React from 'react';
import FontAwesome from 'react-fontawesome';
import './Spinner.scss';

export default function Spinner() {
  return (
    <FontAwesome className='Spinner' name='circle-notch' spin />
  );
}

