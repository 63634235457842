import bind from 'bind-decorator';
import { addSeconds, differenceInSeconds } from 'date-fns';
import React from 'react';
import { Alert, AlertProps } from 'reactstrap';
import './Toaster.scss';

export type ShowToast = (children: React.ReactNode, props?: Partial<AlertProps>) => void;

interface Toaster {
  showToast: ShowToast;
}

const ToasterContext = React.createContext<Toaster>({
  showToast() { ; }
});

interface Toast {
  children: React.ReactNode;
  props: Partial<AlertProps>;
  expires: number;
}

interface State {
  toasts: Toast[];
}

export const Toaster = ToasterContext.Consumer;

const Timeout = 3;

export class ToasterProvider extends React.PureComponent<{}, State> {
  public readonly state: State = { toasts: [] };
  private readonly toaster: Toaster = { showToast: this.showToast };

  public render() {
    const d = Date.now();
    return <ToasterContext.Provider value={this.toaster}>
      {this.props.children}
      <div className='Toaster'>
        {this.state.toasts.map((t, i) => <Alert {...t.props} isOpen={d < t.expires} key={i} toggle={() => this.hideToast(t)}>{t.children}</Alert>)}
      </div>
    </ToasterContext.Provider>
  }

  private hideToast(toast: Toast) {
    this.setState(({ toasts }) => ({
      toasts: toasts.map(t => t === toast ? { ...t, expires: 0 } : t)
    }))
  }

  @bind
  private showToast(children: React.ReactNode, props: Partial<AlertProps> = {}) {
    const d = new Date();

    this.setState(({ toasts }) => ({
      toasts: [
        ...toasts.filter(t => differenceInSeconds(d, t.expires) < 1),
        {
          children,
          props: { color: 'secondary', ...props },
          expires: addSeconds(d, Timeout).valueOf()
        }
      ]
    }));

    window.setTimeout(() => this.forceUpdate(), Timeout * 1000);
  }
}
