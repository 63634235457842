import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Button } from 'reactstrap';
import './Header.scss';
import { UserConsumer } from './UserContext';

interface Props {
  onLogout(): void;
}

export default function Header({ onLogout }: Props) {
  return (
    <UserConsumer>
      {user =>
        user && <div className='Header'>
          <div className='login-details'>
            <FontAwesome name='user' />
            <span>{user.name} ({user.email})</span>
          </div>
          <Button size='sm' color='primary' onClick={onLogout}>Logout</Button>
        </div>
      }
    </UserConsumer>
  );
}

